<template>
  <div flex="~ items-center justify-between" p="4 t-2">
    <div flex="~ items-center" font="500" text="xs white">
      <template v-if="query.disCode">
        <img :height="16" :src="cobrand.logo" class="mr-1 align-middle" />
        <span>{{ cobrand.name || t('cobrand') }}</span>
        <span class="mx-1">|</span>
        <span>{{ t('poweredBy') }}</span>
      </template>
      <img :width="32" src="~assets/icons/azgo-logo-white.png" class="ml-1 self-end" />
    </div>
    <div v-if="showCurrency">
      <span class="align-middle text-xs text-white font-800 underline" @click="showPopup = true">
        {{ currentCurrency }}
      </span>
      <span class="ml-0.5 align-middle text-lg text-white">$</span>
      <!-- <van-image :width="20" :height="20" :src="UserIcon" class="align-middle ml-2"></van-image> -->
    </div>
  </div>
  <template v-if="showCurrency">
    <van-popup v-model:show="showPopup" position="bottom" closeable safe-area-inset-bottom round>
      <div class="px-4 py-3 text-center align-middle text-lg font-500">
        {{ t('selectCurrency') }}
      </div>
      <div class="px-4">
        <div class="mb-3 pt-4 text-[#121212] font-500">
          {{ t('allCurrency') }}
        </div>
        <van-radio-group v-model="currentCurrency" class="pb-4">
          <BaseAZRadio v-for="item in currencyInfo" :key="item.currency" :name="item.currency">
            <div class="flex items-center p-2 text-sm text-[#121212]">
              <div class="w-[62px] text-base font-500">
                {{ item.currency }}
              </div>
              <div class="text-base">
                {{ item.fullName }}
              </div>
            </div>
          </BaseAZRadio>
        </van-radio-group>
      </div>
    </van-popup>
  </template>
</template>

<script setup lang="ts">
// import { fetchChageCurrency } from '@/api/home'

defineProps({
  showCurrency: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()
const { query } = useRoute()

const showPopup = ref(false)

const userInfoStore = useUserStore()
const commonDataStore = useCommonStore()

const currencyInfo = computed(() => commonDataStore.commonData?.currencyInfo || [])
const currentCurrency = computed({
  get: () => userInfoStore.userInfo.currency,
  set: (value: string) => {
    // fetchChageCurrency(value)
    userInfoStore.userInfo.currency = value
    showPopup.value = false
  },
})

const cobrand = computed(() => {
  const { partnerInfo } = commonDataStore.commonData || {}
  return {
    logo: partnerInfo?.bgImg,
    name: partnerInfo?.disName,
  }
})
</script>

<i18n lang="json">
{
  "en": {
    "cobrand": "cobrand",
    "poweredBy": "powered by",
    "selectCurrency": "Select Currency",
    "allCurrency": "All Currency"
  }
}
</i18n>
