<template>
  <van-radio v-bind="attrs">
    <slot></slot>
    <template #icon="props">
      <div
        v-if="props.checked"
        class="h-5 w-5 flex items-center justify-center rounded-full bg-[#121212]"
      >
        <svg
          width="11"
          height="8"
          viewBox="0 0 11 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4L4 7L9.5 1.5"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div v-else class="box-border h-5 w-5 b-0.5 border-[#B2B2B2] rounded-full border-solid"></div>
    </template>
  </van-radio>
</template>

<script setup lang="ts">
defineProps({} as never)

const attrs = useAttrs()
</script>
